import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import router from './router'
import adapter from "webrtc-adapter";
import store from './store/store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Notifications from 'vue-notification'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/style.css';
import './assets/css/fonts.css';
import './assets/css/global.css';
import './assets/lib/fontawesome/css/all.css';
import Embed from 'v-video-embed';
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';
import browserDetect from "vue-browser-detect-plugin";

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(Notifications);
Vue.use(adapter);
Vue.use(Embed);
Vue.use(browserDetect)


export default new Vue({
    vuetify,
    router: router,
    store,
    render: h => h(App),
}).$mount('#app')