<template>
  <div role="main" class="main">
    <section class="common-page bg-prelude h-100 pb-3 pt-3">
      <div class="container h-100 d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="col-12">
            <h1 class="text-yellow large-font">404</h1>
            <h1 class="text-yellow">Page Not Found</h1>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  export default {
    data () {
      return{

      }
    },
    computed: {

    },
    methods: {

    },
    watch: {

    }
  }
</script>
